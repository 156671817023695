/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CalculationResponseDto = {
    carbonQuantity: number;
    methodology: CalculationResponseDto.methodology;
    key?: string;
};

export namespace CalculationResponseDto {

    export enum methodology {
        ICAO = 'ICAO',
        ADEME = 'ADEME',
        CO2_EMISSIEFACTOREN_NL = 'CO2-EMISSIEFACTOREN-NL',
        DEFRA = 'DEFRA',
        TIM = 'TIM',
        US_EPA = 'US-EPA',
        SQUAKE = 'SQUAKE',
        FAILED_LOOKUP = 'Failed Lookup',
    }


}

